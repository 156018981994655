<template>
    <div class="mobileContainer">
        <header-container>
            <ParticularOPDAppointmentHeader />
        </header-container>
        <div v-if="dataLoading">
            <hospital-skeleton-loader />
        </div>
        <div class="bodyContainer" v-if="!dataLoading">
            <div style="width: 100%; height: 100%; background-color: white">
                <div class="timingContainer">
                    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px;">
                        <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.estimated_time_to_visit")}}:</div>
                        <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.appointmentData.doctorInfo.visitTime }} ({{ bookingInfo.appointmentData.doctorInfo.slotDate }})</div>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: flex-end; gap: 8px;">
                        <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.PackageAppointments.booking_id")}}:</div>
                        <div style="font-weight: 600; font-size: 16px;">{{ bookingID }}</div>
                    </div>
                </div>
                <!-- token container start  -->
                <div style="display: flex; justify-content: space-between; align-items: center; border-top: 1px solid #e0e0e0; padding-top: 20px;">
                    <div style="display: flex; flex-direction: column; gap: 10px" v-if="bookingInfo.appointmentData.appointmentStatus != 'ACTIVE'">
                        <div style="font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.appointment_status")}}:</div>
                        <div style="background: #DAEEE880; color: #27ae60; padding: 8px; border-radius: 4px; font-weight: 500; font-size: 12px; width: 80px;">{{ bookingInfo.appointmentData.appointmentStatus }}</div>
                    </div>
                    <div style="display: flex; flex-direction: column; gap: 10px" v-else>
                        <div style="font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.doctor_status")}}:</div>
                        <div style="background: #DAEEE880; color: #27ae60; padding: 8px; border-radius: 4px; font-weight: 500; font-size: 12px; min-width: 80px;">{{ getDoctorStatus(bookingInfo.appointmentData.doctorInfo.doctorStatus)}}</div>
                    </div>
                    <div class="TokenContainer">
                        <div style="color: #1467bf; font-weight: 600; font-size: 14px;">{{$t("Customer.Appointments.token_no")}}:</div>
                        <div style="background-color: #1467bf;  border-radius: 50%; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; color: white; font-weight: 600;">{{ bookingInfo.appointmentData.doctorInfo.tokenNumber }}</div>
                    </div>
                </div>
                <div :class="calculateClassForHospitalInfo()">
                    <div style="display: flex; flex-direction: column; gap: 12px; padding: 12px;">
                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                            <div class="doctorNameAndLogo">
                                <div class="doctorProfile">
                                    <img :src="bookingInfo.appointmentData.doctorInfo.doctorLogoURL" style="border-radius: 50%;"/>
                                </div>
                                <div class="doctorName">
                                    <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.appointmentData.doctorInfo.doctorName }}</div>
                                    <div style="color: #1467BF; font-weight: 400; font-size: 14px;" class="specialtyBox">General Practioner</div>
                                </div>
                            </div>
                            <div>
                                <v-btn 
                                    outlined
                                    color="#e8e8eb"
                                    @click="() => {toggleViewDoctorDialog(true)}"
                                    :loading="viewDoctorLoading"
                                    :disabled="viewDoctorLoading"
                                >
                                    <div style="color: #828282; font-weight: 400; font-size: 14px;">{{$t("Customer.OpdAppointments.View")}}</div>
                                </v-btn>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px;">
                            <div style="font-size: 14px; color: #6f717e;">{{$t("Customer.CartPage.Address")}}:</div>
                            <div class="hospitalNameAndLogo">
                                <div class="hospitalLogo">
                                    <img :src="bookingInfo.appointmentData.doctorInfo.hospitalLogo" style="object-fit: cover; width: 100%; height: auto; border-radius: 12px;"/>
                                </div>
                                <div class="hospitalName">
                                    <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.appointmentData.doctorInfo.hospitalName }}</div>
                                    <div style="color: #4f4f4f; font-size: 12px; font-weight: 400;">{{  bookingInfo.appointmentData.doctorInfo.hospitalAddress }}</div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px; margin-top: 20px;" v-if="bookingInfo.appointmentData.appointmentStatus == 'ACTIVE'">
                    <div style="font-size: 14px; font-weight: 600; color: #333">{{$t("Customer.Appointments.alloted_slots")}} <span style="color: #828282;">({{$t("Customer.Appointments.last_updated")}} <span style="color: #1467BF; font-weight: 500;">{{ currentTime }}</span>)</span></div>
                    <alloted-slots-view-component  :tokenInfo="tokenList"/>
                    <div style="margin-top: 10px;">
                        <alloted-slot-box-color-info />
                    </div>
                </div>
                    <!-- token container end  -->
                <!-- patient container start -->
                    <appointment-patient-info-component  :patientInfo="bookingInfo.appointmentData.patientInfo"/>
                    <appointment-patient-contact-component :patientInfo="bookingInfo.appointmentData.patientInfo"/>
                    <div  v-if="bookingInfo.appointmentData.appointmentStatus == 'ACTIVE' " style="display: flex; justify-content: flex-start; margin: 10px 0px;">
                    <v-btn outlined style="color: #eb5757;" @click="toggleBottomSheet" :disabled="isCancelDisable"> {{$t("Customer.Home.Cancel")}}</v-btn>
                    </div>
                    <!-- patient container end -->
            </div>
        </div>
        <div class="bottomSheet" v-if="bottomSheet && !dataLoading">
            <div class="selectRegionBottomSheetHeading">
                <div style="font-size: 14px; font-weight: 500;">{{$t("Customer.Appointments.cancel_appointment")}}</div>
                <img src="https://d3vsnl8idgwrlw.cloudfront.net/closeCircular.svg"   style="cursor: pointer; width: 20px; height: 20px;" @click="toggleBottomSheet" />
            </div>
            <div style="display: flex; align-items: center; padding: 12px; gap: 8px;">
                <div style="background-color: #ebf5fa; width: 48px; border-radius: 8px; height: 48px; display: flex; justify-content: center; align-items: center;">
                    <div style="background-color: #1467bf; width: 30px; height: 30px; border-radius: 50%; display: flex; justify-content: center; align-items: center">
                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/tickMark.svg"   style="cursor: pointer; width: 20px; height: 20px;"/>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: space-around; height: 48px;">
                    <div style="font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.for_your_visit_schedule")}}</div>
                    <div style="display: flex; align-items: center; gap: 5px;">
                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/calendarGrey.svg"   style="cursor: pointer; width: 20px; height: 20px;"/>
                        <div style="font-size: 14px; font-weight: 600;"> {{ bookingInfo.appointmentData.doctorInfo.slotTime }}</div>
                    </div>
                </div>
            </div>
            <div style="display: flex; align-items: flex-start; gap: 20px; flex-direction: column; padding: 20px; border-top: 1px solid #e0e0e0;">
                <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px; width: 100%;">
                    <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.reason_for_cancellation")}}</div>
                    <select  v-model="selectedReason" class="dropdown" placeholder="Choose From">
                        <option v-for="item in cancelReason" :value="item.name" :key="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px; width: 100%;">
                    <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.additional_comments")}}</div>
                    <div>
                        <textarea placeholder="Comments" class="commentBox" cols="40" rows="5"> </textarea>
                    </div>
                </div>
            </div>
            <div style="display: flex; gap: 10px; justify-content: space-between; flex-direction: row-reverse; border-top: 1px solid #e0e0e0; padding-top: 15px; padding-right: 24px;">
                <v-btn outlined color="#eb5757" @click="cancellAppointment" :disabled="cancellLoader" :loading="cancellLoader">{{$t("Customer.Appointments.yes_i_comfirm")}}</v-btn>
            </div>
        </div>
        <div v-if="viewDoctorModel" transition="slide-y-transition">
            <view-doctor-component @toggleViewDoctorDialog="toggleViewDoctorDialog" :doctorInfo="doctorData" :modelValue="viewDoctorModel"/>
        </div>    
    </div>
</template>

<script>
import axios from "axios";
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import MainContainer from '../../../templates/mainContainer.vue';
import hospitalBanner from '../componentUsedInPage/viewHospitalPageComponents/hospitalBannerComp.vue';
import SelectSpecialityAndSearch from '../componentUsedInPage/viewHospitalPageComponents/selectSpecialityAndSearch.vue';
import ViewParticalurHospitalHeader from '../componentUsedInPage/viewHospitalPageComponents/viewParticalurHospitalHeader.vue';
import ViewParticularDoctorComp from '../componentUsedInPage/viewHospitalPageComponents/viewParticularDoctorComp.vue';
import HospitalHeaderComp from '../componentUsedInPage/opdHomePage/hospitalHeaderComp.vue';
import SelectRegionAndSeachComp from '../componentUsedInPage/opdHomePage/selectRegionAndSeachComp.vue';
import SingleHospitalComp from '../componentUsedInPage/opdHomePage/singleHospitalComp.vue';
import { axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
import BookingSuccessHeader from '../componentUsedInPage/bookingSuccessPage/bookingSuccessHeader.vue';
import DoctorInfoComponent from '../componentUsedInPage/bookingSuccessPage/doctorInfoComponent.vue';
import PatientInfoComponent from '../componentUsedInPage/bookingSuccessPage/patientInfoComponent.vue';
import ParticularOPDAppointmentHeader from '../componentUsedInPage/particularOPDAppointmentPage/particularOPDAppointmentHeader.vue';
import AppointmentPatientInfoComponent from '../componentUsedInPage/particularOPDAppointmentPage/appointmentPatientInfoComponent.vue';
import AppointmentPatientContactComponent from '../componentUsedInPage/particularOPDAppointmentPage/appointmentPatientContactComponent.vue';
import AllotedSlotsViewComponent from '../componentUsedInPage/particularOPDAppointmentPage/allotedSlotsViewComponent.vue';
import AllotedSlotBoxColorInfo from '../componentUsedInPage/particularOPDAppointmentPage/allotedSlotBoxColorInfo.vue';
import HospitalSkeletonLoader from '../componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';
import ViewDoctorComponent from '../componentUsedInPage/commonAcrossPages/viewDoctorComponent.vue';


export default {
  components: {HeaderContainer, HospitalHeaderComp, SelectRegionAndSeachComp, SingleHospitalComp, ViewParticalurHospitalHeader, MainContainer, hospitalBanner, SelectSpecialityAndSearch, ViewParticularDoctorComp, BookingSuccessHeader, DoctorInfoComponent, PatientInfoComponent, ParticularOPDAppointmentHeader, AppointmentPatientInfoComponent, AppointmentPatientContactComponent, AllotedSlotsViewComponent, AllotedSlotBoxColorInfo, HospitalSkeletonLoader, ViewDoctorComponent },
    name: 'particularOPDAppointment',
    data: function() {
        return {
            token: null,
            bookingID: '',
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            allDetailsDiv: null,
            dependent_members: [],
            currentCustomer: null,
            doctorList: [],
            sheet: true,
            selectedReason: null,
            bottomSheet: false,
            bookingInfo: {},
            dataLoading: true,
            cancellLoader: false,
            doctorData: null,
            viewDoctorModel: false,
            viewDoctorLoading: false,
            tokenList: [],
            val: false,
            currentTime: '',
            cancelReason: []
        };
    },
    computed: {
        isCancelDisable() {
            switch(this.tokenList[this.bookingInfo.appointmentData.doctorInfo.tokenNumber - 1].patientStatus) {
                case 'SEEING_DOCTOR':
                    return true;
                default:
                    return false;
            }
        }
    },
    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        this.currentTime = this.getCurrentTime12HourFormat();
        this.bookingID = this.$route.params.bookingID;
        try {
            axios_auth_instance_hba_customer.get('user/cancelReason').then((res) => {
                this.cancelReason = res.data.reasons;
            });

            let apiResponse = await axios_auth_instance_hba_customer.get(`/appointment/particularAppointment/${this.bookingID}`);
            this.bookingInfo = apiResponse.data.appointmentInfo;
            this.tokenList = this.bookingInfo.tokenList;
            if (this.bookingInfo.appointmentData.appointmentStatus == 'ACTIVE') {
                this.completedFlag = false;
                setInterval(async () => {
                    let tokenInfo = await axios_auth_instance_hba_customer.get(`/appointment/particularAppointment/${this.bookingID}`);
                    this.bookingInfo = tokenInfo.data.appointmentInfo;
                    this.tokenList = [...tokenInfo.data.appointmentInfo.tokenList]; 
                    this.currentTime = this.getCurrentTime12HourFormat();
                }, 60000);
            }
            this.dataLoading = false;
        } catch (error) {
            console.log('Prakersh', error);
        }

        this.userPageViewTable();
    },

    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'HBAOPDAppointmentDetiails',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'HBAOPDAppointmentDetiails',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        // isCancelDisable() {
        //     console.log(this.tokenList[this.bookingInfo.appointmentData.doctorInfo.tokenNumber - 1].patientStatus);
      
        // },
        getDoctorStatus(value) {
            switch(value) {
                case 'YET_TO_ARRIVE':
                    return 'Yet to arrive'
                case 'DOCTOR_IN':
                    return 'Doctor In'
                default:
                    return 'Doctor Out'
            }
        },
        getCurrentTime12HourFormat() {
            const date = new Date();
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;

            const minutesStr = minutes < 10 ? '0' + minutes : minutes;

            const timeStr = `${hours}:${minutesStr} ${ampm}`;

            return timeStr;
        },

        async toggleViewDoctorDialog(value) {
            if(value) {
                try {
                    if (this.doctorData == null) {
                        this.viewDoctorLoading = true;
                        let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.bookingInfo.appointmentData.doctorInfo.doctorID}`);
                        this.doctorData = apiResponse.data.doctorDetails;
                    }
                    this.viewDoctorModel = true;
                } catch (error) {
                    console.log(error);
                } finally {
                    this.viewDoctorLoading = false;
                }
            } else {
                this.viewDoctorModel = false;
            }
        },
        async cancellAppointment() {
            try {
                this.cancellLoader = true;
                await axios_auth_instance_hba_customer.put(`/appointment/cancell/${this.bookingID}`, {
                    reason: this.selectedReason
                });
                this.$router.push({
                    name: 'listOPDAppointmentsPage'
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.cancellLoader = false;
            }
        },
        toggleBottomSheet() {
            this.bottomSheet = !this.bottomSheet;
        },
        calculateClassForHospitalInfo() {
            return 'topBorder';
            if (this.dataLoading) return 'topBorder';
            // if (this.completedFlag) return 'topBorder';
            // return 'allBorder';
        }
    },
};

</script>
<style  scoped>
.commentBox {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px 13px 12px 13px;

}
.dropdown {
    width: 100%;
    border: 1px solid#e0e0e0;
    padding: 12px 13px 12px 13px;
    border-radius: 8px;

}
.bottomSheet {
    height: 475px;
    width: 100%;
    background: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: 1px solid #e0e0e0;
    position: absolute;
    bottom: 0;
    max-width: 425px;
}
.timingContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
}
.TokenContainer {
    width: 150px;
    height: 44px;
    padding: 8px 8px 8px 16px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 16px;
    background: #1467BF1A;
}
.mobileContainer {
    margin: auto;
    width: 425px;
    /* height: 100vh; */
    background-color: white;
    /* max-height: 100vh; */
    /* overflow: hidden; */
}
.bodyContainer {
    width: 100%;
    height: 88vh;
    overflow: auto;
    padding: 0 16px;
    background-color: white;
    /* background: red; */
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.selectRegionBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.stateChipsContainer {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex-grow: 1;
    overflow-y: scroll;
    max-height: 200px;
    border-top: 1px solid #e8e8eb;

}
.stateBottomSheetBottomBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px 12px 16px;
    border-top: 1px solid #e8e8eb;
}
.searchStateBottomContainer {
    display: flex;
    flex-direction: column;
}
.v-dialog__content >>> .v-bottom-sheet {
    box-shadow: none !important;
}
.doctorName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}
.doctorProfile {
    border-radius: 50%;
    max-height: 56px;
    max-width: 56px;
    height: 56px;
    width: 56px;
    border: 1px solid #e0e0e0;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.doctorNameAndLogo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
}
.specialtyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4F5FF;
    padding: 3px 8px 3px 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.hospitalLogo {
    max-height: 40px;
    max-width: 40px;
    height: 40px;
    width: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalNameAndLogo {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    font-size: 14px !important;
}
.allBorder {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-top: 16px;
}
.topBorder {
    border-top: 1px solid #e0e0e0;
    margin-top: 16px;

}
@media screen and (max-width: 425px){
.mobileContainer {
    width: 100%;
}
}
</style>